import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { appService } from "../../App/app.service";
import CurrentStageOfQualifiedPipelineTable from "./CurrentStageOfQualifiedPipelineTable";
import {
  convertHalfToQuarters,
  drilldownBubblesFormatter,
  drilldownLabelMap,
  filterNameDrilldownTitleMap,
} from "./utils";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../../components/Drilldown/Drilldown";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
import DatePicker from "../../components/DatePicker_v2";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultSalesType,
  getDefaultACV,
  dateSubtractorMoment,
  dateFormatter,
  customDateFormatterNew,
  dateFormatterMomentV4,
} from "../../util/customFunctions";
import * as d3 from "d3";
import NumberFormat from "react-number-format";

function NumberFormatCustomv2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        (values.value > 0 || [null, "", undefined].includes(values.value)) &&
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
      }}
      thousandSeparator
      isNumericString={false}
      // prefix="$"
      allowNegative={false}
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString={false}
      // prefix="$"
      allowNegative={false}
    />
  );
}

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
});
const monthYearObj = {
  1: "Jan.",
  2: "Feb.",
  3: "Mar.",
  4: "Apr.",
  5: "May",
  6: "Jun.",
  7: "Jul.",
  8: "Aug.",
  9: "Sep.",
  10: "Oct.",
  11: "Nov.",
  12: "Dec.",
};

const monthFormmatter = (month) => {
  const [year, mon] = month.split("-");
  const monValue = monthYearObj[parseInt(mon)];
  return `${monValue} ${year}`;
};
class CurrentStateOfQualifiedPipeline extends React.Component {
  filters = [
    "Projected_Close_Date_At_Time_Fiscal_Quarter",

    "Region_Rollup",
    "Booking_Type_NL_CS_US",
    "Acct_Segment",
    "team",
    "rep",
    "Acct_Vertical",
    "Solution_Cat",
    "Acct_Segment",
    "Cust_Type",
    "Acct_Industry",
    "Acct_Size_Segment",
    "ACV_Range",
    "Lead_Source",
    "Record_Type_Name__c",
  ];
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  oppTypeName = "Type";
  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          projectedQuarter: "Projected_Close_Date_At_Time_Fiscal_Quarter",
          ACV_Range: "ACV_Range",
          Product_Line: "Product_Line",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
        }
      : this.props.company === "C0008"
      ? {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Product_Line",
          projectedQuarter: "Projected_Close_Date_At_Time_Fiscal_Quarter",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
        }
      : {
          Open_Date: "from",
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          projectedQuarter: "Projected_Close_Date_At_Time_Fiscal_Quarter",
          ACV_Range: "ACV_Range",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
          Lead_Source: "Lead_Source",
          Open_Date_Initial_Fiscal_Quarter: "Open_Date_Initial_Fiscal_Quarter",
          Qualified_Date_Initial_Fiscal_Quarter:
            "Qualified_Date_Initial_Fiscal_Quarter",
          Open_Date_Initial_Calendar_Year: "Open_Date_Initial_Calendar_Year",
          Open_Date_Initial_Calendar_Month: "Open_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Month:
            "Qualified_Date_Initial_Calendar_Month",
          Qualified_Date_Initial_Calendar_Year:
            "Qualified_Date_Initial_Calendar_Year",
          Created_Fiscal_Quarter: "Created_Fiscal_Quarter",
          Created_Month: "Created_Month",
          Created_Year: "Created_Year",
          Record_Type_Name__c: "Record_Type_Name__c",
        };
  state = {
    Projected_Close_Date_At_Time_Fiscal_Quarter: ["All"],
    Record_Type_Name__c: ["All"],
    Qualified_Date_Initial_Fiscal_Quarter: ["All"],
    Created_Year: ["All"],
    Created_Fiscal_Quarter: ["All"],
    Created_Month: ["All"],
    Open_Date_Initial_Fiscal_Quarter: ["All"],
    Open_Date_Initial_Calendar_Year: ["All"],
    Open_Date_Initial_Calendar_Month: ["All"],
    Qualified_Date_Initial_Calendar_Month: ["All"],
    Qualified_Date_Initial_Calendar_Year: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: "",
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filtersForDrilldownPcfq: ["All"],
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Acct_Vertical: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    ACV_Range: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    Lead_Source: ["All"],
    Product_Line: ["All"],
    milestone_stage: "",
    selectCol: "Open_Date_Initial_Fiscal_Quarter",
    group_by: "group_by_quarter",
    isMonth: false,
    tab: "Summary",
    acv: "",
    acvlt: "",
    toggleValue: "AcvSort",
    Open_Date: "2023-03-17",
    location: "",
    secondLevelLocation: "",
    filtersForDrilldown: [],
    from: "",
    last: 180,
    timeFrame: "",
    minDateForFrom: "",
    maxDateForFrom: "",
    errorTextFrom: "",
  };
  constructor(props) {
    super(props);
  }
  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };
  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (
      !this.state.Projected_Close_Date_At_Time_Fiscal_Quarter.includes("All")
    ) {
      filtersArray = [
        ...filtersArray,
        this.state.Projected_Close_Date_At_Time_Fiscal_Quarter,
      ];
    }

    if (
      this.state.Projected_Close_Date_At_Time_Fiscal_Quarter.includes("All")
    ) {
      filtersArray = [...filtersArray, "All"];
    }

    return filtersArray[0];
  };
  componentDidMount() {
    this.props.getfunnelCompareFilters();
    // this.handleGo();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const fromDateComponents =
        this.props.pipelineDateFilters.prior6Month.split("-");

      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );

      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;

      const minDateSplit = this.props.pipelineMinMax.minDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit = this.props.pipelineMinMax.maxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );
      console.log("pipeline_as_of", fromDate);

      const stageSequence = getDefaultSelectedStage(
        this.props.funnelCompareFilters.filter(
          (f) => f.type === "milestone_stage"
        )
      );
      let stage_names = [];
      const stageLength = this.props.funnelCompareFilters.filter(
        (f) => f.type === "milestone_stage"
      ).length;
      let col = "Open_Date_Initial_Fiscal_Quarter";
      if (stageLength === 1) {
        col = "Qualified_Date_Initial_Fiscal_Quarter";
      }
      this.props.funnelCompareFilters.map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence == stageSequence) stage_names = i.stage_name;
        }
      });
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      let oppTypes = ["All"];
      let oppTypeColumn;
      if (this.props.funnelCompareFilters.filter((s) => s.oppType).length > 0) {
        oppTypeColumn = this.props.funnelCompareFilters.filter(
          (s) => s.oppType
        )[0].oppType;
        this.oppTypeName = oppTypeColumn;
        this.variableCols = {
          ...this.variableCols,
          [oppTypeColumn]: oppTypeColumn,
        };
        oppTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.oppType),
          oppTypeColumn
        );
        this.setState({
          ...prevState,
          [oppTypeColumn]: oppTypes,
        });
      }
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }

      console.log(
        getDefaultACV(
          this.props.funnelCompareFilters.filter((s) => s.type === "acv")
        ),
        "ACV"
      );
      this.setState((prevState) => ({
        ...prevState,
        earliestOpenStage: getDefaultSelectedStage(
          this.props.funnelCompareFilters.filter((it) => it.type === "stage")
        ),
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        from: fromDate,
        fromDisplay: customDateFormatterNew(fromDate),
        activeFrom: fromDate,
        selectCol: col,
        acv:
          getDefaultACV(
            this.props.funnelCompareFilters.filter((s) => s.type === "acv")
          ) !== ""
            ? d3.format(",")(
                String(
                  getDefaultACV(
                    this.props.funnelCompareFilters.filter(
                      (s) => s.type === "acv"
                    )
                  )
                )
              )
            : getDefaultACV(
                this.props.funnelCompareFilters.filter((s) => s.type === "acv")
              ),
        Created_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "Created_Fiscal_Quarter"
        ),
        Projected_Close_Date_At_Time_Fiscal_Quarter:
          getDefaultSelectedFiscalQuarters(
            this.props.funnelCompareFilters,
            "projected_close_fiscal_quarter"
          ),
        Open_Date_Initial_Fiscal_Quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "Open_Date_Initial_Fiscal_Quarter"
        ),
        milestone_stage: stageSequence,
        filtersForDrilldownPcfq: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "Open_Date_Initial_Fiscal_Quarter"
        ),
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
      }));
    }
  }
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
      secondLevelLocation: "",
    });
  };
  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };
  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };
  handleChangeLocation = (
    { fromStages, toStages },
    { fromHalves, toHalves }
  ) => {
    console.log(
      "drilldown_filters",
      { fromStages, toStages },
      { fromHalves, toHalves }
    );
    const currentFilters = this.props.currentFilters;
    const data = this.props.currentStateOfQualifiedPipelineData;

    const fromStagesList = fromStages
      .map((fromStage) => data.groupedStageToStage[fromStage])
      .flat();
    const toStagesList = toStages
      .map((toStage) => data.groupedStageToStage[toStage])
      .flat();
    const fromQuarters = fromHalves
      .map((fromHalf) =>
        convertHalfToQuarters([fromHalf], data.halfToQuarterMap)
      )
      .flat();
    const toQuarters = toHalves
      .map((toHalf) => convertHalfToQuarters([toHalf], data.halfToQuarterMap))
      .flat();

    // // const fromQuarters = data.halfToQuarterMap[fromHalf];
    // // const toQuarters = data.halfToQuarterMap[toHalf];
    // console.log(
    //   { fromStagesList, toStagesList },
    //   { fromQuarters, toQuarters },
    //   toHalf
    // );
    const filters = this.props.currentFilters.filter(
      (it) => it.name !== "Projected_Close_Date_At_Time_Fiscal_Quarter"
    );
    const selectedStage = this.state.earliestOpenStage;
    const stagesList = this.props.funnelCompareFilters
      .filter((it) => it.type === "stage" && it.sequence < selectedStage)
      .map((it) => it.stage_name);

    console.log(stagesList, selectedStage, "stagesList");
    filters.push(
      {
        name: "Projected_Close_Date_At_Time_Fiscal_Quarter",
        value: fromQuarters.filter((it) =>
          this.state[this.variableCols.projectedQuarter].includes(it)
        ),
        string: false,
      },
      {
        name: "Projected_Close_Fiscal_Quarter",
        value: toQuarters,
        string: false,
      },
      {
        name: "Stage_At_Time_T",
        value: fromStagesList.filter((it) => !stagesList.includes(it)),
        string: false,
      },
      {
        name: "Stage",
        value: toStagesList,
        string: false,
      }
    );
    if (
      fromStagesList.length > 0 &&
      toStagesList.length > 0 &&
      fromQuarters.length > 0 &&
      toQuarters.length > 0
    ) {
      // filters = ;
      this.props.getDrilldown(
        filters
          .filter((it) => {
            if (it.name === "Open_Date") {
              return it;
            }
            if (Array.isArray(it.value) && it.value.length > 0) {
              return it;
            }
            return it;
          })
          .filter(
            (it) =>
              !["EarliestStageOnwards", "StagesBeforeEarliest"].includes(
                it.name
              )
          )
      );
      const filtersForDrilldown = this.passFiltersForDrilldown(filters);
      const repObject = this.props.funnelCompareFilters.filter(
        (filter) =>
          filter.type === "rep" && this.state.rep.includes(filter.UserID)
      )[0];
      console.log(repObject, this.state.rep, "filtersForDrilldown");
      console.log("filtersDrilldown", filtersForDrilldown);
      this.setState({
        ...this.state,
        location: "drilldownOnGraph",
        filtersForDrilldown: filtersForDrilldown.map((it) => {
          if (it.title === "rep") {
            return { ...it, title: "Rep", value: this.state.rep };
          } else if (it.name === "Open_Date") {
            const [month, day, year] = it.value.split("-");

            // Reconstruct the date in the desired format
            const setDate = `${year}-${month}-${day}`;
            return {
              ...it,
              value: dateFormatterMomentV4(setDate),
            };
          } else if (it.value.length === 0) {
            return {
              ...it,
              title: drilldownLabelMap(it.name, this.props.Value_Label, true),
              value: "All",
            };
          }

          return {
            ...it,
            title: drilldownLabelMap(it.name, this.props.Value_Label, true),
            value: drilldownBubblesFormatter[it.title]
              ? drilldownBubblesFormatter[it.title](it.value)
              : it.value,
          };
        }),
      });
    }
  };
  /**
   *
   * @param {any[]} filters
   */
  passFiltersForDrilldown = (filters) => {
    const titleFilters = filters.map((it) => ({
      ...it,
      title: filterNameDrilldownTitleMap[it.name]
        ? filterNameDrilldownTitleMap[it.name]
        : it.name.split("_").join(" "),
    }));
    return titleFilters;
  };
  setFromDate = (date) => {
    const dateSplit = {
      month: date.getMonth(),
      year: date.getFullYear(),
      date: date.getDate(),
    };
    const toDateSplit = "2024-04-16".split("-");
    const dateSplitTo = {
      month: parseInt(toDateSplit[0]) - 1,
      date: parseInt(toDateSplit[1]),
      year: parseInt(toDateSplit[2]),
    };

    if (
      dateSubtractorMoment(dateSplit, 0).utc().valueOf() >
      dateSubtractorMoment(dateSplitTo, 0).utc().valueOf()
    ) {
      console.log(
        "as_of_pipeline",
        dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY")
      );
      this.setState({
        ...this.state,
        from: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        Open_Date: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        errorTextFrom: "Data not available",
      });
    } else {
      this.setState({
        ...this.state,
        from: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        Open_Date: dateSubtractorMoment(dateSplit, 0).format("MM-DD-YYYY"),
        errorTextFrom: "",
      });
    }
  };
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    console.log(this.props.lkpFilters);
    let filters1 = this.filters;
    if (this.props.company === "C0001") {
      filters1.push("deal_category__c");
    } else if (this.props.company === "C0008") {
      filters1.push("cross_sell1__c");
      filters1.push("Type");
      console.log("api_call_filters", filters1);
    } else if (
      ["C0010", "C0016", "C0009", "C0013"].includes(this.props.company)
    ) {
      filters1.push("Type");
    } else if (this.props.company === "C0003") {
      filters1.push("Product_Line");
    }

    filters1 = [...new Set(filters1)];

    // console.log(filters1)

    console.log("filters1", filters1);
    // console.log(this.state.milestone_stage, filters1)
    // console.log(this.props.funnelCompareFilters.filter(i => i.type === 'Open_Date_Initial_Fiscal_Quarter' && i['Open_Date_Initial_Fiscal_Quarter'] === this.state['Open_Date_Initial_Fiscal_Quarter'][0])[0], this.state)
    let filters = filters1
      .map((f) => {
        console.log(f, "filters1");
        // console.log(this.state[f], f)
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.stage_name !== undefined) {
        if (i.sequence === this.state.milestone_stage)
          stage_names = [i.stage_name];
      }
    });
    console.log(filters, "filters1");
    let stage_namesOnwards = [];
    let stage_namesBefore = [];
    this.props.funnelCompareFilters
      .filter((it) => it.type === "stage")
      .map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence >= this.state.earliestOpenStage)
            stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
        }
      });
    this.props.funnelCompareFilters
      .filter((it) => it.type === "stage")
      .map((i) => {
        if (i.sequence !== undefined) {
          if (i.sequence < this.state.earliestOpenStage)
            stage_namesBefore = [...stage_namesBefore, i.stage_name];
        }
      });
    filters = [
      ...filters,
      {
        name: "EarliestStageOnwards",
        value: stage_namesOnwards,
        string: true,
      },
      {
        name: "StagesBeforeEarliest",
        value: stage_namesBefore,
        string: true,
      },
    ];
    if (this.state.group_by === "group_by_quarter") {
      filters = filters.filter((f) => f.name !== "group_by");
    }
    let ismonth = false;
    if (this.state.group_by === "group_by_month") {
      ismonth = true;
    }
    const openDate = {
      name: "Open_Date",
      value: this.state.from,
      string: false,
    };
    filters = [...filters, openDate];
    console.log(filters, "api_call_filters");
    if (this.props.company === "C0008") {
      if (!this.state[this.variableCols.Acct_Vertical].includes("All")) {
        console.log(
          this.state[this.variableCols.Acct_Vertical],
          "api_call_filters"
        );
        filters.push({
          name: this.variableCols.Acct_Vertical,
          value: this.state[this.variableCols.Acct_Vertical],
          string: true,
        });
      }
    }
    filters = filters.filter((it) => {
      if (it.name === "Open_Date") {
        return it;
      }
      if (Array.isArray(it.value) && it.value.length > 0) {
        return it;
      }
      return it;
    });

    if (
      this.state.acv !== null &&
      this.state.acv !== 0 &&
      this.state.acv !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    } else if (this.state.acv === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (
      this.state.acvlt !== null &&
      this.state.acvlt !== 0 &&
      this.state.acvlt !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    } else if (this.state.acvlt === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    this.setState({
      ...this.state,
      filtersForDrilldownPcfq: this.passFiltersForDrilldown(filters),
      selectCol: "",
      isMonth: ismonth,
    });
    // filters.push(

    // );

    this.props.getData(filters);
  };
  onAcvChange = (e, value) => {
    console.log(value);
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (e, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeTimeframe = (e) => {
    if (e.target.value !== 1) {
      const projectedClosedDateMapping = {
        7: this.props.pipelineDateFilters.prior7Days.split("-"),
        15: this.props.pipelineDateFilters.prior15Days.split("-"),
        30: this.props.pipelineDateFilters.prior1Month.split("-"),
        90: this.props.pipelineDateFilters.prior3Month.split("-"),
        180: this.props.pipelineDateFilters.prior6Month.split("-"),
        365: this.props.pipelineDateFilters.prior1Year.split("-"),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.pipelineDateFilters.currentDate.split("-");
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      console.log("pipeline_as_of", fromDate);
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.statem, [e.target.name]: e.target.value });
    }
  };
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item container xs={12}>
          {addHeaderdescription(
            `Pipeline Progression Analysis`,
            "Explore the current state of pipeline from prior timeframes"
          )}
        </Grid>
        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid
              item
              spacing={2}
              xs={4}
              container
              component={Paper}
              style={{ margin: `7px 3px 7px 3px` }}
            >
              <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel
                    className={classes.filterLabel}
                    style={{
                      color: !this.state[
                        this.variableCols.projectedQuarter
                      ].includes("All")
                        ? "#4472c4"
                        : "#000",
                    }}
                    id="select-fiscalQuarter-label"
                  >
                    Proj. Close Fiscal Quarter:
                  </InputLabel>
                  <Select
                    value={this.state[this.variableCols.projectedQuarter]}
                    onChange={this.handleChange}
                    name={this.variableCols.projectedQuarter}
                    data-test="projected-close-fiscal-quarter"
                    multiple
                  >
                    <MenuItem value={"All"}>All</MenuItem>

                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters.map((y) =>
                        y.type === "projected_close_fiscal_quarter" ? (
                          <MenuItem
                            key={y["projected_close_fiscal_quarter"]}
                            value={y["projected_close_fiscal_quarter"]}
                          >
                            {y["projected_close_fiscal_quarter"]}
                          </MenuItem>
                        ) : null
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel
                    id="select-stage-label"
                    style={{ color: "#4472c4", width: 144 }}
                  >
                    Earliest Open Stage:
                  </InputLabel>
                  <Select
                    labelId="select-stage-label"
                    id="select-stage"
                    value={this.state.earliestOpenStage}
                    onChange={this.handleChangeEarliestOpenStage}
                    label="Earliest Open Stage:"
                    name="earliestOpenStage"
                  >
                    {this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((it) => it.type === "stage")
                        .map(
                          (y) =>
                            y.sequence && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={3}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4" }}
                    >
                      Pipeline as of:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.last}
                      onChange={this.handleChangeTimeframe}
                      label="Last:"
                      name="last"
                      disabled={this.state.timeFrame === "custom"}
                    >
                      {[
                        { text: "Custom", value: 1 },
                        { text: "7 days ago", value: 7 },
                        { text: "15 days ago", value: 15 },
                        { text: "1 month ago", value: 30 },
                        { text: "3 months ago", value: 90 },
                        { text: "6 months ago", value: 180 },
                        { text: "1 year ago", value: 365 },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <DatePicker
                    label="At the end of:"
                    setDate={(date) => this.setFromDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForFrom}
                    max={this.state.maxDateForFrom}
                    current={this.state.from}
                    errorText={this.state.errorTextFrom}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={4}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={12} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.Acct_Vertical
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : this.props.company === "C0008"
                        ? "Product Line"
                        : "Solution Category:"}
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.Acct_Vertical]}
                      onChange={this.handleChange}
                      name={this.variableCols.Acct_Vertical}
                      data-test={this.variableCols.Acct_Vertical}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Acct_Vertical &&
                              y[this.variableCols.Acct_Vertical] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Acct_Vertical] <
                            b[this.variableCols.Acct_Vertical]
                              ? -1
                              : a[this.variableCols.Acct_Vertical] >
                                b[this.variableCols.Acct_Vertical]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Acct_Vertical]}
                              value={y[this.variableCols.Acct_Vertical]}
                            >
                              {
                                y[
                                  this.props.company === "C0008"
                                    ? "label"
                                    : this.variableCols.Acct_Vertical
                                ]
                              }
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={6}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={4} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Opportunity Type:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003" ? "Region:" : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      {this.props.company === "C0003"
                        ? "Segment:"
                        : "Customer Segment:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Adding ACV Range Filter */}
            <Grid container item style={{ padding: 10, paddingTop: 15 }} xs={2}>
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={12} sm={12} style={{}}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.ACV_Range
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.Value_Label} Range:
                    </InputLabel>
                    <Select
                      value={this.state[this.variableCols.ACV_Range]}
                      onChange={this.handleChange}
                      name={this.variableCols.ACV_Range}
                      data-test={this.variableCols.ACV_Range}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {console.log(
                        this.props.funnelCompareFilters
                          .filter((y) => y.type === this.variableCols.ACV_Range)
                          .map((y) => y[this.variableCols.ACV_Range])
                      )}
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.ACV_Range &&
                              y[this.variableCols.ACV_Range] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.ACV_Range]}
                              value={y[this.variableCols.ACV_Range]}
                            >
                              {y[this.variableCols.ACV_Range]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {this.props.company !== "C0003" &&
              this.props.company !== "C0013" && (
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.Lead_Source
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Lead Source:
                        </InputLabel>
                        <Select
                          data-test="Lead_Source"
                          value={this.state[this.variableCols.Lead_Source]}
                          onChange={this.handleChange}
                          name={this.variableCols.Lead_Source}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Lead_Source &&
                                  y[this.variableCols.Lead_Source] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Lead_Source]}
                                  value={y[this.variableCols.Lead_Source]}
                                >
                                  {y[this.variableCols.Lead_Source]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {/*Deal Category filter */}
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{}}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0008" && (
              <>
                {" "}
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.cross_sell1__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Sales Type:
                        </InputLabel>
                        <Select
                          data-test="cross_sell1__c"
                          value={this.state[this.variableCols.cross_sell1__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.cross_sell1__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.cross_sell1__c &&
                                  y[this.variableCols.cross_sell1__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.cross_sell1__c]}
                                  value={y[this.variableCols.cross_sell1__c]}
                                >
                                  {y[this.variableCols.cross_sell1__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[this.variableCols.Type].includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Type:
                        </InputLabel>
                        <Select
                          data-test="Type"
                          value={this.state[this.variableCols.Type]}
                          onChange={this.handleChange}
                          name={this.variableCols.Type}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Type &&
                                  y[this.variableCols.Type] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Type]}
                                  value={y[this.variableCols.Type]}
                                >
                                  {y[this.variableCols.Type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/*Opp. Type filter */}
            {this.props.company !== "C0008" &&
              this.props.company !== "C0003" &&
              this.props.company !== "C0002" &&
              this.props.company !== "C0001" &&
              this.props.company !== "C0015" &&
              this.props.company !== "C0013" &&
              this.props.funnelCompareFilters.filter((it) => it.oppType) && (
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{}}
                  >
                    <Grid item xs={12} sm={12} style={{}}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols[this.oppTypeName]
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Opp. Type:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={
                            this.state[this.variableCols[this.oppTypeName]]
                          }
                          onChange={this.handleChange}
                          name={this.variableCols[this.oppTypeName]}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols[this.oppTypeName] &&
                                  y[this.variableCols[this.oppTypeName]] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols[this.oppTypeName]]}
                                  value={y[this.variableCols[this.oppTypeName]]}
                                >
                                  {y[this.variableCols[this.oppTypeName]]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {/* C0010 Opp Record Type */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{}}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Record_Type_Name__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={
                          this.state[this.variableCols.Record_Type_Name__c]
                        }
                        onChange={this.handleChange}
                        name={this.variableCols.Record_Type_Name__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type ===
                                  this.variableCols.Record_Type_Name__c &&
                                y[this.variableCols.Record_Type_Name__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Record_Type_Name__c]}
                                value={y[this.variableCols.Record_Type_Name__c]}
                              >
                                {y[this.variableCols.Record_Type_Name__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0003" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{}}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 8 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Product_Line
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Product Cat.:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.Product_Line]}
                        onChange={this.handleChange}
                        name={this.variableCols.Product_Line}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Product_Line &&
                                y[this.variableCols.Product_Line] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Product_Line]}
                                value={y[this.variableCols.Product_Line]}
                              >
                                {y["label"]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={4}
              lg={4}
              md={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{}}
              >
                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    // defaultValue={0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustomv2,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} sm={6} style={{ paddingTop: 10 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((f) => f.type === "acv")
                        .map((d) => d3.format(",")(String(d.acv)))
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== "" &&
                              this.state.acvlt !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                data-test="go"
                variant="contained"
                color="primary"
                onClick={this.handleGo}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        <Grid container xs={12} item style={{ marginTop: "20px" }}>
          {this.props.currentStateOfQualifiedPipelineData !== "" ? (
            <Grid container item xs={12}>
              <CurrentStageOfQualifiedPipelineTable
                data={this.props.currentStateOfQualifiedPipelineData}
                handleChangeLocation={this.handleChangeLocation}
                Value_Label={this.props.Value_Label}
              />
            </Grid>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justifyContent="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={400}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          {this.state.location === "drilldownOnGraph" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={"Current State of Qualified Pipeline"}
                back={this.handleChangeLocationBack}
                filters={this.state.filtersForDrilldown}
              >
                <Drilldown
                  header={"Opportunities in Pipeline Created Mix"}
                  body={this.props.drilldownOnGraphData}
                  company={this.props.company}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
          {this.state.secondLevelLocation === "drilldownOppId" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={"Opportunity Change Events"}
                back={this.handleChangeLocationBack}
                stepBack={this.stepBack}
                filters={[]}
              >
                <ExpectedAcvPage body={this.props.ExpectedAcvData} />
              </SingleLevelDrilldown>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user,
    pipelineCreatedMixFilters,
    lkpFilters,
    currentStateOfQualifiedPipelineData,
    currentFilters,
    ExpectedAcvData,
    pipelineMinMax,
    pipelineDateFilters,
    currentStateOfQualifiedPipelineDrilldown,
  } = state.app;

  return {
    company: user.company,
    drilldownOnGraphData: currentStateOfQualifiedPipelineDrilldown,
    currentFilters,
    lkpFilters,
    ExpectedAcvData,
    Value_Label: user.Value_Label,
    currentStateOfQualifiedPipelineData,
    funnelCompareFilters: pipelineCreatedMixFilters,
    pipelineMinMax,
    pipelineDateFilters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_pipeline_created_mix_filters_request" });
    appService.getCurrentStateOfQualifiedPipelineFilters().then((json) => {
      var lkpFilters = Object.values(
        json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
      );
      json.minMax = json.message.minMax;
      json.dateFilters = json.message.dateFilters;
      json.message = json.message.computedFilters;
      json.lkpFilters = lkpFilters;

      dispatch({ type: "get_pipeline_created_mix_filters_success", json });
      dispatch({ type: "get_pipeline_created_mix_analysis_data_request" });
      //default stage filter
      const stageLength = json.message.filter(
        (f) => f.type === "milestone_stage"
      ).length;
      const stageSequence = getDefaultSelectedStage(
        json.message.filter((f) => f.type === "stage")
      );

      const defaultStages = json.message
        .filter((item) => item.sequence === stageSequence)
        .map((i) => i.stage_name)
        .filter(Boolean);
      let filters = [
        {
          name: "Projected_Close_Date_At_Time_Fiscal_Quarter",
          value: getDefaultSelectedFiscalQuarters(
            json.message,
            "projected_close_fiscal_quarter"
          ),
          string: true,
        },
      ];
      if (stageLength === 1) {
        filters = [
          {
            name: "Projected_Close_Date_At_Time_Fiscal_Quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "projected_close_fiscal_quarter"
            ),
            string: true,
          },
        ];
      }
      const defaultACV = getDefaultACV(
        json.message.filter((f) => f.type === "acv")
      );
      if (defaultACV !== "") {
        filters = [
          ...filters,
          { name: "acv", value: defaultACV, string: false },
        ];
      }
      if (json.message.filter((s) => s.oppType).length > 0) {
        let oppTypeColumn = json.message.filter((i) => i.oppType)[0].oppType;
        filters =
          getDefaultSalesType(
            json.message.filter((i) => i.oppType),
            oppTypeColumn
          )[0] === "All"
            ? [...filters]
            : [
                ...filters,
                {
                  name: oppTypeColumn,
                  value: getDefaultSalesType(
                    json.message.filter((i) => i.oppType),
                    oppTypeColumn
                  ),
                },
              ];
      }
      if (company === "C0008") {
        const salesFilters = getDefaultSalesType(
          json.message.filter((s) => s.type === "cross_sell1__c"),
          "cross_sell1__c"
        );
        const dealsFilters = getDefaultSalesType(
          json.message.filter((s) => s.type === "Type"),
          "Type"
        );
        filters =
          salesFilters[0] === "All"
            ? filters
            : [...filters, { name: "cross_sell1__c", value: salesFilters }];
        filters =
          dealsFilters[0] === "All"
            ? filters
            : [...filters, { name: "Type", value: dealsFilters }];
      }
      dispatch({
        type: "get_current_state_of_qualified_pipeline_data_request",
      });

      const fromDate = json.dateFilters.prior6Month;
      const parts = fromDate.split("-");
      const formattedDate = `${parts[1]}-${parts[2]}-${parts[0]}`;
      console.log(formattedDate);

      filters = [
        ...filters,
        {
          name: "Open_Date",
          value: formattedDate,
          string: false,
        },
      ];
      dispatch({ type: "current_selected_filters", filters: filters });

      let stage_namesOnwards = [];
      let stage_namesBefore = [];
      json.message
        .filter((it) => it.type === "stage")
        .map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence >= stageSequence)
              stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
          }
        });
      json.message
        .filter((it) => it.type === "stage")
        .map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence < stageSequence)
              stage_namesBefore = [...stage_namesBefore, i.stage_name];
          }
        });
      filters = [
        ...filters,
        {
          name: "EarliestStageOnwards",
          value: stage_namesOnwards,
          string: true,
        },
        {
          name: "StagesBeforeEarliest",
          value: stage_namesBefore,
          string: true,
        },
      ];
      appService.getCurrentStateOfQualifiedPipeline(filters).then(
        (json) => {
          dispatch({
            type: "get_current_state_of_qualified_pipeline_data_success",
            json,
          });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_current_state_of_qualified_pipeline_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({
              type: "get_current_state_of_qualified_pipeline_data_failure",
              error,
            });
        }
      );
    });
  },
  getDrilldown: (filters) => {
    dispatch({
      type: "get_current_state_of_qualified_pipeline_drilldown_request",
    });
    appService.getCurrentStateOfQualifiedPipelineDrilldown(filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              //closed_date: item.closed_date !== undefined ? item.closed_date.split('T')[0] : item.projected_close_date.split('T')[0],
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({
          type: "get_current_state_of_qualified_pipeline_drilldown_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_current_state_of_qualified_pipeline_drilldown_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_current_state_of_qualified_pipeline_drilldown_failure",
            error,
          });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  getData: (filters) => {
    dispatch({ type: "get_current_state_of_qualified_pipeline_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getCurrentStateOfQualifiedPipeline(filters).then(
      (json) => {
        dispatch({
          type: "get_current_state_of_qualified_pipeline_data_success",
          json,
        });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_current_state_of_qualified_pipeline_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_current_state_of_qualified_pipeline_data_failure",
            error,
          });
      }
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CurrentStateOfQualifiedPipeline));
