import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import { styles } from "../NewFunnel/FunnelStyles";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getDefaultACV,
  getUniqueValues,
  getDefaultSalesType,
} from "../../util/customFunctions";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import MomentumAnalysisTable from "./MomentumAnalysisTable";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

class MomentumAnalysis extends React.Component {
  state = {
    Opportunity_Record_Type: ["All"],
    Record_Type_Name__c: ["All"],
    closed_fiscal_year: ["All"],
    closed_month: ["All"],
    quarter: ["All"],
    rep: ["All"],
    closed_fiscal_quarter: ["All"],
    Cust_Type: ["All"],
    region: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    custType: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [],
    acvPreset: "All",
    acv: 0,
    acvlt: 0,
    defaultACV: "",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "overview",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    selectedDrilldownStage: "",
    lastStage: false,
    activeFilters: ["All"],

    filtersForDrilldown: [],
    repFilter: ["All"],
    teamFilter: ["All"],
    Type: ["All"],
    toggleValue: "count",
    Product_Line: ["All"],
  };

  filters = [
    "closed_fiscal_year",
    "closed_month",
    "quarter",
    "rep",
    "closed_fiscal_quarter",
    "team",
    "Cust_Type",
    "Acct_Industry",
    "Acct_Vertical",
    "custType",
    "Booking_Type_NL_CS_US",
    "Acct_Size_Segment",
    "Acct_Segment",
    "Lead_Source",
    "Opp_Created_By",
    "Solution_Cat",
    "region",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "Product_Line",
    "Opportunity_Record_Type",
    "Record_Type_Name__c",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  componentDidMount() {
    this.props.getFunnelFilters(this.props.company);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      const defaultFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters.closedFiscalQuarters,
        "closed_fiscal_quarter"
      );
      var defaultQuarter =
        defaultFQuarter.length > 0 ? defaultFQuarter : ["All"];
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelFilters.salesType,
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelFilters.dealType,
          "Type"
        );
      }
      const defaultACVSelection = getDefaultACV(this.props.funnelFilters.acv);
      this.setState({
        ...prevState,
        closed_fiscal_quarter: defaultQuarter,
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        acv:
          defaultACVSelection === ""
            ? ""
            : getDefaultACV(this.props.funnelFilters.acv) > 0
            ? d3.format(",")(
                String(getDefaultACV(this.props.funnelFilters.acv))
              )
            : getDefaultACV(this.props.funnelFilters.acv),
        defaultACV: defaultACVSelection,
        filtersForDrilldown: [
          {
            title: "Closed Fiscal Quarter",
            value: defaultQuarter,
          },
        ],
        activeFilters: [
          { name: "closed_fiscal_quarter", value: defaultQuarter },
        ],
      });
    }
  }

  handleChange = (e) => {
    if (
      e.target.name === "closed_fiscal_year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        closed_month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleChangeToggle = (e) => {
    this.setState({
      ...this.state,
      toggleValue: e.target.value,
    });
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (
      this.state.acv !== null &&
      this.state.acv !== 0 &&
      this.state.acv !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    } else if (this.state.acv === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.props.getMomentumAnalysisData(filters);
    this.setState({
      ...this.state,
      activeFilters: filters,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      repFilter: this.state.rep,
      teamFilter: this.state.team,
    });
  };

  orderRepsByLastName = () => {
    let reps = this.props.funnelFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) =>
                  f !== "closed_fiscal_year" &&
                  f !== "closed_month" &&
                  f !== "quarter"
              ),
              "closed_fiscal_quarter",
            ]
          : [
              ...this.state.disabled.filter(
                (f) => f !== "closed_fiscal_quarter"
              ),
              "closed_fiscal_year",
              "closed_month",
              "quarter",
            ],
    });
  };

  handleChangeLocation = (location, obj) => {
    console.log(obj);
    if (location !== "overview") {
      var qfq = obj.row === "Total" ? undefined : obj.row;
      var quarter = obj.cell;
      console.log(qfq, quarter);
      if (quarter === "Total") {
        if (!["", null, undefined].includes(obj.rowCell)) {
          quarter = this.props.funnelData.data.filter(
            (s) => s.closed_fiscal_quarter === "Total" && s.qfq === obj.rowCell
          );
          if (quarter.length >= 1) {
            quarter = quarter[0].closed_fiscal_quarterv2;
          } else {
            quarter = this.props.funnelData.data
              .map((s) => s.closed_fiscal_quarter)
              .filter(getUniqueValues)
              .filter((s) => s !== "Total");
          }
        } else {
          quarter = this.props.funnelData.data
            .map((s) => s.closed_fiscal_quarter)
            .filter(getUniqueValues)
            .filter((s) => s !== "Total");
        }
      }
      var filters = [
        {
          name: "closed_fiscal_quarter",
          value: Array.isArray(quarter) ? quarter : [quarter],
        },
      ];
      var drilldownFilters = [
        {
          title: "Closed Fiscal Quarter",
          value: Array.isArray(quarter)
            ? quarter.length > 4
              ? "All"
              : quarter
            : [quarter],
        },
      ];
      if (qfq !== undefined) {
        filters = [
          ...filters,
          {
            name: "qualified_fiscal_quarter",
            value: Array.isArray(qfq) ? qfq : [qfq],
          },
        ];
        if (qfq !== "") {
          drilldownFilters = [
            ...drilldownFilters,
            {
              title: "Qualified Fiscal Quarter",
              value: Array.isArray(qfq) ? qfq.flat() : [qfq],
            },
          ];
        }
      } else {
        drilldownFilters = [
          ...drilldownFilters,
          { title: "Qualified Fiscal Quarter", value: "All" },
        ];
      }

      if (!this.state.Record_Type_Name__c.includes("All")) {
        drilldownFilters = [
          ...drilldownFilters,
          { title: "Opp Record Type", value: this.state.Record_Type_Name__c },
        ];
      }

      var currentFilters = this.props.currentFilters.filter(
        (s) => s.name !== "closed_fiscal_quarter"
      );
      filters = [...currentFilters, ...filters];
      var keyNames = ["Closed Fiscal Quarter", "Qualified Fiscal Quarter"];
      var filtersForDrilldown = this.state.filtersForDrilldown.filter(
        (i) => !keyNames.includes(i.title)
      );

      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
        filtersForDrilldown: [...drilldownFilters, ...filtersForDrilldown],
      });
      this.props.getMomentumAnalysisDrilldown(filters, obj.rowKey);
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Quarter",
          value: this.state.closed_fiscal_quarter.sort(),
        },
      ];
    }
    if (!this.state.closed_fiscal_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Year",
          value: this.state.closed_fiscal_year.sort(),
        },
      ];
    }
    if (!this.state.closed_month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.closed_month
            .sort((a, b) => (a < b ? -1 : 1))
            .map((i) => this.months[i - 1]),
        },
      ];
    }
    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (
      !this.state.acv !== null &&
      this.state.acv !== 0 &&
      this.state.acv !== ""
    )
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Greater Than`,
          value:
            "$ " +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (
      !this.state.Acct_Industry.includes("All") ||
      !this.state.Acct_Vertical.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: "C0003" ? this.state.Acct_Vertical : this.state.Acct_Industry,
        },
      ];
    if (!this.state.Acct_Size_Segment.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value:
            this.props.company === "C0003"
              ? this.state.Acct_Vertical
              : this.state.Acct_Size_Segment,
        },
      ];
    if (!this.state.Cust_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value:
            this.props.company === "C0003"
              ? this.state.region
              : this.state.Cust_Type,
        },
      ];
    if (!this.state.Lead_Source.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];
    if (
      this.props.company !== "C0003" &&
      !this.state.deal_category__c.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];
    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];
    if (!this.state.Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Type", value: this.state.Type },
      ];

    if (!this.state.Opportunity_Record_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];

    if (!this.state.Product_Line.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0008" ? "Product Line" : "Product Cat.",
          value: this.props.funnelFilters.Product_Line.filter((s) =>
            this.state.Product_Line.includes(s.Product_Line)
          ).map((item) => item.label),
        },
      ];

    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
      secondLevelLocation: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Momentum Analysis
          </Typography>
          <Typography variant="body1">
            Examine how quickly opportunities are won after being sales
            qualified.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Momentum Analysis",
          "Examine how quickly opportunities are won after being sales qualified."
        )}

        {this.props.funnelFilters ? (
          <>
            {/* dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.closed_fiscal_year[0] !== "All" ||
                      this.state.closed_month[0] !== "All"
                    }
                  >
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Closed Fiscal Quarter:
                    </InputLabel>
                    <Select
                      data-test="closed-fiscal-quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      label="Closed Fiscal Quarter"
                      name="closed_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.closedFiscalQuarters
                          .filter((item) => item.closed_fiscal_quarter !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.closed_fiscal_quarter}
                              value={y.closed_fiscal_quarter}
                            >
                              {y.closed_fiscal_quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                  >
                    <InputLabel
                      id="select-year-label"
                      style={{
                        color: !this.state.closed_fiscal_year.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Closed Calendar Year:
                    </InputLabel>
                    <Select
                      labelId="select-year-label"
                      id="select-year"
                      value={this.state.closed_fiscal_year}
                      onChange={this.handleChange}
                      label="Year"
                      name="closed_fiscal_year"
                      data-test="year"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.years
                          .sort()
                          .reverse()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl
                    disabled={this.state.closed_fiscal_quarter[0] !== "All"}
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      id="select-month-label"
                      style={{
                        color: !this.state.closed_month.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Closed Calendar Month:
                    </InputLabel>
                    <Select
                      labelId="select-month-label"
                      id="select-month"
                      data-test="month"
                      value={this.state.closed_month}
                      onChange={this.handleChange}
                      label="Month"
                      name="closed_month"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((y) => (
                        <MenuItem key={y} value={y}>
                          {this.months[y - 1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* rep & team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
              md={4}
            >
              {this.props.funnelFilters !== "" ? (
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={
                        this.props.funnelFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      data-test="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rep:"
                          variant="standard"
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        label="Team"
                        data-test="team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.teams
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container component={Paper} justify="center">
                  {" "}
                  <Skeleton
                    variant="text"
                    animation="wave"
                    height={40}
                    width={290}
                  />
                </Grid>
              )}
            </Grid>

            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              lg={2}
            >
              {/* <Grid item spacing={2} xs={8} xl={9} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ paddingTop: 0, height: "0px" }}
                >
                  {this.state.defaultACV === "" ? (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.acv.map((d) =>
                          d3.format(",")(String(d.acv))
                        )
                      }
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onAcvChange}
                      value={this.state.acv}
                      data-test="acv"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} >= :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acv !== null &&
                                this.state.acv !== 0 &&
                                this.state.acv !== ""
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.acv.map((d) =>
                          d3.format(",")(String(d.acv))
                        )
                      }
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onAcvChange}
                      value={this.state.acv}
                      data-test="acv"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} >= :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acv !== null && this.state.acv !== 0
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid> */}
              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                >
                  GO
                </Button>
              </Grid>
            </Grid>

            {/* extra filters 1 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-Cust_Type-label"
                      style={{
                        color:
                          !this.state.Acct_Industry.includes("All") ||
                          !this.state.Acct_Vertical.includes("All")
                            ? "#4472c4"
                            : "#000",
                      }}
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      labelId="select-Cust_Type-label"
                      id="select-Cust_Type"
                      value={
                        this.props.company === "C0003"
                          ? this.state.Acct_Vertical
                          : this.state.Acct_Industry
                      }
                      onChange={this.handleChange}
                      name={
                        this.props.company === "C0003"
                          ? "Acct_Vertical"
                          : "Acct_Industry"
                      }
                      data-test="Acct_Industry"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.industries
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  {this.props.company === "C0003" ? (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Industry-label"
                        style={{
                          color: !this.state.Acct_Segment.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Customer Segment:
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Industry-label"
                        id="select-Acct_Industry"
                        value={this.state.Acct_Segment}
                        onChange={this.handleChange}
                        name="Acct_Segment"
                        data-test="customer_segment"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.custSeg
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Industry-label"
                        style={{
                          color: !this.state.Acct_Size_Segment.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Customer Segment:
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Industry-label"
                        id="select-Acct_Industry"
                        value={this.state.Acct_Size_Segment}
                        onChange={this.handleChange}
                        name="Acct_Size_Segment"
                        data-test="customer_segment"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.custSeg
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  {this.props.company === "C0003" ? (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Industry-label"
                        style={{
                          color: !this.state.region.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        {"Region:"}
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Industry-label"
                        id="select-Acct_Industry"
                        value={this.state.region}
                        onChange={this.handleChange}
                        name="region"
                        data-test="Cust_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.regions
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Industry-label"
                        style={{
                          color: !this.state.Cust_Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        {this.props.company === "C0003"
                          ? "Region:"
                          : "Customer Type:"}
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Industry-label"
                        id="select-Acct_Industry"
                        value={this.state.Cust_Type}
                        onChange={this.handleChange}
                        name="Cust_Type"
                        data-test="Cust_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.regions
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* extra filters 2 */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={this.props.company === "C0003" ? 6 : 6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-region-label"
                      style={{
                        color: !this.state.Lead_Source.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Lead Source:
                    </InputLabel>
                    <Select
                      labelId="select-region-label"
                      id="select-region"
                      value={this.state.Lead_Source}
                      onChange={this.handleChange}
                      name="Lead_Source"
                      data-test="leadsource"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.leadSource
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={this.props.company === "C0003" ? 4 : 4}
                  style={{ paddingTop: 0 }}
                >
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-Acct_Industry-label"
                      style={{
                        color: !this.state.Opp_Created_By.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Opportunity Created By:
                    </InputLabel>
                    <Select
                      labelId="select-Acct_Industry-label"
                      id="select-Acct_Industry"
                      value={this.state.Opp_Created_By}
                      onChange={this.handleChange}
                      name="Opp_Created_By"
                      data-test="opp_created_by"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.oppCreatedBy
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                {!["C0003", "C0008"].includes(this.props.company) && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Acct_Industry-label"
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        labelId="select-Acct_Industry-label"
                        id="select-Acct_Industry"
                        value={this.state.Solution_Cat}
                        onChange={this.handleChange}
                        name="Solution_Cat"
                        data-test="solution_category"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.solutionCat
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.props.company === "C0003" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Booking_Type_NL_CS_US.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-Acct_Industry-label"
                      >
                        Opportunity Type:
                      </InputLabel>
                      <Select
                        value={this.state.Booking_Type_NL_CS_US}
                        onChange={this.handleChange}
                        name="Booking_Type_NL_CS_US"
                        data-test="Booking_Type_NL_CS_US"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Booking_Type_NL_CS_US.sort(
                            (a, b) =>
                              a["Booking_Type_NL_CS_US"] <
                              b["Booking_Type_NL_CS_US"]
                                ? -1
                                : a["Booking_Type_NL_CS_US"] >
                                  b["Booking_Type_NL_CS_US"]
                                ? 1
                                : 0
                          )
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.props.company === "C0008" && (
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Product_Line-label"
                        style={{
                          color: !this.state.Product_Line.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Product Line:
                      </InputLabel>
                      <Select
                        labelId="select-Product_Line-label"
                        id="select-Product_Line"
                        value={this.state.Product_Line}
                        onChange={this.handleChange}
                        name="Product_Line"
                        data-test="Product_Line"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Product_Line.map((y) => (
                            <MenuItem
                              key={y.Product_Line}
                              value={y.Product_Line}
                            >
                              {y.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* Adding Acv Value */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              md={4}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  {this.state.defaultACV === "" ? (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.acv.map((d) =>
                          d3.format(",")(String(d.acv))
                        )
                      }
                      onChange={this.onAcvChange}
                      value={this.state.acv}
                      data-test="acv"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} >= :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4, marginBottom: 0 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acv !== null &&
                                this.state.acv !== 0 &&
                                this.state.acv !== ""
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.acv.map((d) =>
                          d3.format(",")(String(d.acv))
                        )
                      }
                      onChange={this.onAcvChange}
                      value={this.state.acv}
                      data-test="acv"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} >= :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4, marginBottom: 0 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acv !== null && this.state.acv !== 0
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  {this.state.defaultACV === "" ? (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.acv.map((d) =>
                          d3.format(",")(String(d.acv))
                        )
                      }
                      onChange={this.onAcvLtChange}
                      value={this.state.acvlt}
                      data-test="acvlt"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} < :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4, marginBottom: 0 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acvlt !== null &&
                                this.state.acvlt !== 0 &&
                                this.state.acvlt !== ""
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.acv.map((d) =>
                          d3.format(",")(String(d.acv))
                        )
                      }
                      onChange={this.onAcvLtChange}
                      value={this.state.acvlt}
                      data-test="acvlt"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} < :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4, marginBottom: 0 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acvlt !== null &&
                                this.state.acvlt !== 0
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/*  */}
            {this.props.company === "C0013" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
                md={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters.OpportunityRecordType &&
                          this.props.funnelFilters.OpportunityRecordType.filter(
                            (y) =>
                              y.type === "Opportunity_Record_Type" &&
                              y.Opportunity_Record_Type !== ""
                          ).map((y) => (
                            <MenuItem
                              key={y.Opportunity_Record_Type}
                              value={y.Opportunity_Record_Type}
                            >
                              {y.Opportunity_Record_Type}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* C0010 Opp Record Type */}
            {this.props.company === "C0010" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                xs={2}
                md={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChange}
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters.oppRecordTypeFilter &&
                          this.props.funnelFilters.oppRecordTypeFilter
                            .filter(
                              (y) =>
                                y.type === "Record_Type_Name__c" &&
                                y.Record_Type_Name__c !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Record_Type_Name__c}
                                value={y.Record_Type_Name__c}
                              >
                                {y.Record_Type_Name__c}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.deal_category__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state.deal_category__c}
                        onChange={this.handleChange}
                        name="deal_category__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.dealCat.map((y) => (
                            <MenuItem
                              key={y.deal_category__c}
                              value={y.deal_category__c}
                            >
                              {y.deal_category__c}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0008" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                justify="space-between"
                xs={4}
                md={4}
                lg={4}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.cross_sell1__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Sales Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state.cross_sell1__c}
                        onChange={this.handleChange}
                        name="cross_sell1__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.salesType.map((y) => (
                            <MenuItem
                              key={y.cross_sell1__c}
                              value={y.cross_sell1__c}
                            >
                              {y.cross_sell1__c}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state.Type}
                        onChange={this.handleChange}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.dealType.map((y) => (
                            <MenuItem key={y.Type} value={y.Type}>
                              {y.Type}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0003" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                justify="space-between"
                xs={2}
                md={2}
                lg={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Product_Line.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Product Cat.:
                      </InputLabel>
                      <Select
                        data-test="Product_Line"
                        value={this.state.Product_Line}
                        onChange={this.handleChange}
                        name="Product_Line"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Product_Line.map((y) => (
                            <MenuItem
                              key={y.Product_Line}
                              value={y.Product_Line}
                            >
                              {y.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          className={classes.grid}
          container
          justify="center"
          style={{ marginTop: "1rem" }}
        >
          <Grid
            item
            xs={12}
            style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
          >
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              {this.props.funnelData !== "" ? (
                <Grid item container xs={12} justify="center">
                  <Grid className={classes.grid} item xs={12}>
                    <DataVisualizer
                      chartName={`Momentum Analysis`}
                      title={` Momentum Analysis`}
                      type={"qfq"}
                      chartType={
                        this.state.toggleValue === "count" ? "number" : "acv"
                      }
                      winACVMixAnalysisData={this.props.funnelData.data.filter(
                        (s) => s.closed_fiscal_quarter !== "Total"
                      )}
                      handleChangeLocation={this.handleChangeLocation}
                      quarter={"closed_fiscal_quarter"}
                      tableComponent={
                        <MomentumAnalysisTable
                          data={this.props.funnelData.data}
                          totalRow={this.props.funnelData.totalData}
                          handleChangeLocation={this.handleChangeLocation}
                          Value_Label={this.props.Value_Label}
                          dataTest={"momentum-analysis"}
                        />
                      }
                      unit={
                        this.state.toggleValue === "count" ? "count" : "acv"
                      }
                      company={this.props.company}
                      clusterTotal={[]}
                      sortByTotal={false}
                      label={""}
                      toggleValue={this.state.toggleValue}
                      handleChangeToggle={this.handleChangeToggle}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item container justify="center">
                  <Skeleton
                    variant="react"
                    animation="wave"
                    height={120}
                    width="90%"
                    style={{ margin: "1rem" }}
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Momentum Analysis"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={"Momentum Analysis"}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    funnelFilters,
    funnelData,
    persistentAlert,
    user,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelFilters,
    funnelData,
    persistentAlert,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: (company) => {
    dispatch({ type: "get_funnel_filters_request" });
    appService.getMomentumAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_funnel_filters_success", json });
        dispatch({ type: "get_funnel_data_request" });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.closedFiscalQuarters,
          "closed_fiscal_quarter"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [
            { name: "closed_fiscal_quarter", value: defaultFiscalQuarter },
          ];
        }
        const defaultACV = getDefaultACV(json.message.acv);
        if (defaultACV !== "") {
          filters = [
            ...filters,
            { name: "acv", value: defaultACV, string: false },
          ];
        }
        // filters=[...filters,{ name: 'acv', value: getDefaultACV(json.message.acv), string: false }]
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.salesType,
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.dealType,
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        dispatch({ type: "current_selected_filters", filters });

        appService.getMomentumAnalysisData(filters).then(
          (json) => {
            dispatch({ type: "get_funnel_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_funnel_data_failure",
                error: "Something went wrong",
              });
            else dispatch({ type: "get_funnel_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_filters_failure", error });
      }
    );
  },
  getMomentumAnalysisData: (filters) => {
    dispatch({ type: "get_funnel_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getMomentumAnalysisData(filters).then(
      (json) => {
        dispatch({ type: "get_funnel_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_data_failure", error });
      }
    );
  },
  getMomentumAnalysisDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getMomentumAnalysisDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

const connectedMomentum = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MomentumAnalysis));
export { connectedMomentum as MomentumAnalysis };
