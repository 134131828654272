import React from "react";
import D3Funnel from "./D3Waterfall";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Divider,
  Button,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import DatePicker from "../../components/DatePicker_v2";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import WaterfallDrilldownBody from "./WaterfallDrilldownBody";
import Drilldown from "../../components/Drilldown/DrilldownV2";
import {
  customDateFormatterNew,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
} from "../../util/customFunctions";
import ExpectedAcvPage from "./ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 250,
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "32%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  repTeamFilterContainer: {
    padding: 10,
    margin: 5,
    width: "29%",
    [theme.breakpoints.down("md")]: {
      width: "37%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  acvFilterContainer: {
    padding: 10,
    margin: 5,
    paddingBottom: 2,
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "35%",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  stageFilterContainer: {
    padding: 5,
    paddingTop: 10,
    margin: 5,
    paddingBottom: 10,
    width: "10%",
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  buttonContainer: {
    padding: 5,
    width: "5%",
    alignSelf: "center",
  },
});

class Funnel extends React.Component {
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  filters = [
    "rep",
    "team",
    "pcfq",
    "Cust_Type",
    "Booking_Type_NL_CS_US",
    "deal_category__c",
    "Acct_Industry",
    "Solution_Cat",
    "Record_Type_Name__c",
  ];

  formatDate = (dt) => {
    let date = dt.toString();
    return date.length < 2 ? `0${date}` : date;
  };

  state = {
    last: 7,
    activelast: 7,
    timeFrame: "window",
    from: "",
    to: "",
    fromDisplay: "",
    toDisplay: "",
    activeFrom: "",
    activeTo: "",
    minDateForTo: this.props.waterfallMinDate,
    maxDateForTo: this.props.waterfallMaxDate,
    minDateForFrom: this.props.waterfallMinDate,
    maxDateForFrom: this.props.waterfallMaxDate,

    errorTextFrom: "",
    errorTextTo: "",
    rep: ["All"],
    team: ["All"],
    Cust_Type: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    disabled: [],
    repTeam: "rep",
    pcfq: ["All"],
    earliestOpenStage: "",
    location: "overview",
    filtersForDrilldown: [{ title: "Rep", value: ["All"] }],
    filtersForDrilldownpcfq: ["All"],
    filtersForDrilldownEarliestOpenStage: 3,
    activeFilters: [],
    selectedDrilldownStage: "",
    selectedDrilldownStageLabel: "",
    secondLevelLocation: null,
    deal_category__c: ["All"],
    Acct_Industry: ["All"],
    Solution_Cat: ["All"],
    Record_Type_Name__c: ["All"],
  };

  orderRepsByLastName = () => {
    let reps = this.props.waterfallFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  componentDidMount() {
    if (this.props.waterfallMaxDate === null) this.props.getWaterfallMinDate();
    else
      this.props.getWaterfallData(
        this.props.waterfallFilters.dateFilters.prior7Days,
        this.props.waterfallFilters.dateFilters.currentDate,
        []
      );

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    D3Funnel.destroy(this._rootNode);
    this._chart = D3Funnel.create(this._rootNode, {
      data: this.props.waterfallData,
      Value_Label: `${this.props.Value_Label}`,
      handleChangeLocation: this.handleChangeLocation,
      company: this.props.company,
      disableStartingEndingPipeline:
        this.props.company === "C0003" ? false : false,
      disableDrilldown: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.waterfallMinDate !== prevProps.waterfallMinDate ||
      this.props.waterfallMaxDate !== prevProps.waterfallMaxDate
    ) {
      // console.log(month)
      // console.log(dateFormatterMoment({year,month:month,date:dt}),year,month,dt)
      const fromDateComponents =
        this.props.waterfallFilters.dateFilters.prior7Days.split("-");
      const toDateComponents =
        this.props.waterfallFilters.dateFilters.currentDate.split("-");
      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      const minDateSplit = this.props.waterfallMinDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit = this.props.waterfallMaxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );
      this.setState({
        ...this.state,
        minDateForTo: minDate,
        maxDateForTo: maxDate,
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        pcfq: getDefaultSelectedFiscalQuarters(
          this.props.waterfallFilters.pcfq,
          "Projected_Close_Fiscal_Quarter"
        ),
        filtersForDrilldownpcfq: getDefaultSelectedFiscalQuarters(
          this.props.waterfallFilters.pcfq,
          "Projected_Close_Fiscal_Quarter"
        ),
        earliestOpenStage: getDefaultSelectedStage(
          this.props.waterfallFilters.stages
        ),
        filtersForDrilldownEarliestOpenStage: getDefaultSelectedStage(
          this.props.waterfallFilters.stages
        ),
        from: fromDate,
        to: toDate,
        fromDisplay: customDateFormatterNew(fromDate),
        toDisplay: customDateFormatterNew(toDate),
        activeFrom: fromDate,
        activeTo: toDate,
      });
    }
    if (this.props.waterfallData !== "" && prevProps.waterfallData === "") {
      this._chart = D3Funnel.create(this._rootNode, {
        data: this.props.waterfallData,
        Value_Label: `${this.props.Value_Label}`,
        handleChangeLocation: this.handleChangeLocation,
        company: this.props.company,
        disableStartingEndingPipeline:
          this.props.company === "C0003" ? false : false,
        disableDrilldown: true,
      });
    }
    if (
      this.props.waterfallData !== prevProps.waterfallData &&
      prevProps.waterfallData !== ""
    ) {
      // D3 Code to update the chart
      D3Funnel.destroy(this._rootNode);
      this._chart = D3Funnel.create(this._rootNode, {
        data: this.props.waterfallData,
        Value_Label: `${this.props.Value_Label}`,
        handleChangeLocation: this.handleChangeLocation,
        company: this.props.company,
        disableStartingEndingPipeline:
          this.props.company === "C0003" ? false : false,
      });
    }
  }

  /* componentWillUnmount() {
      D3Funnel.destroy(this._rootNode);
  } */

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  handleChange = (e) => {
    if (e.target.value !== 1) {
      const projectedClosedDateMapping = {
        7: this.props.waterfallFilters.dateFilters.prior7Days.split("-"),
        15: this.props.waterfallFilters.dateFilters.prior15Days.split("-"),
        30: this.props.waterfallFilters.dateFilters.prior1Month.split("-"),
        90: this.props.waterfallFilters.dateFilters.prior3Month.split("-"),
        180: this.props.waterfallFilters.dateFilters.prior6Month.split("-"),
        365: this.props.waterfallFilters.dateFilters.prior1Year.split("-"),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.waterfallFilters.dateFilters.currentDate.split("-");
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.statem, [e.target.name]: e.target.value });
    }
  };

  handleChangeFilter = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  handleChangeRepTeam = (e) => {
    this.setState({
      ...this.state,
      repTeam: e.target.value,
      disabled:
        e.target.value === "rep"
          ? [...this.state.disabled.filter((f) => f !== "rep"), "team"]
          : [...this.state.disabled.filter((f) => f !== "team"), "rep"],
    });
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  plot = () => {
    const fromSplit = this.state.from.split("-");
    const toSplit = this.state.to.split("-");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.waterfallFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.waterfallFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
          string: "true",
        };
      })
      .filter((f) => !f.value.includes("All"))
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_namesOnwards = [];
    let stage_namesBefore = [];
    this.props.waterfallFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
      }
    });
    this.props.waterfallFilters.stages.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence < this.state.earliestOpenStage)
          stage_namesBefore = [...stage_namesBefore, i.stage_name];
      }
    });
    filters = [
      ...filters,
      {
        name: "EarliestStageOnwards",
        value: stage_namesOnwards,
        string: true,
      },
      {
        name: "StagesBeforeEarliest",
        value: stage_namesBefore,
        string: true,
      },
    ];

    this.props.getWaterfallData(this.state.from, this.state.to, filters);
    this.setState({
      ...this.state,
      fromDisplay: customDateFormatterNew(this.state.from),
      toDisplay: customDateFormatterNew(this.state.to),
      activeFrom: this.state.from,
      activeTo: this.state.to,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      filtersForDrilldownpcfq: this.passFiltersForDrilldown2(),
      filtersForDrilldownEarliestOpenStage:
        this.passFiltersForDrilldownEarliestOpenStage(),
      activeFilters: filters,
      activelast: this.state.last,
    });
  };

  handleChangeLocation = (location, stage, name) => {
    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage,
      selectedDrilldownStageLabel: name,
      secondLevelLocation: location,
    });
    if (location !== "overview") {
      const fromSplit = this.state.from.split("-");
      const toSplit = this.state.to.split("-");
      let filters = this.props.selectedFilters;

      this.props.getWaterfallDrilldownOnGraph({
        stage: stage,
        from: this.state.activeFrom,
        to: this.state.activeTo,
        filters: filters,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (
      this.state.disabled.filter((i) => i === "Booking_Type_NL_CS_US")
        .length === 0
    ) {
      if (this.props.company === "C0003") {
        filtersArray = [
          ...filtersArray,
          {
            title: "Opportunity Type",
            value: this.state.Booking_Type_NL_CS_US,
          },
        ];
      }
    }
    if (this.state.disabled.filter((i) => i === "Acct_Industry").length === 0) {
      if (this.props.company === "C0002") {
        filtersArray = [
          ...filtersArray,
          {
            title: "Industry",
            value: this.state.Acct_Industry,
          },
        ];
      }
    }
    if (
      this.state.disabled.filter((i) => i === "deal_category__c").length === 0
    ) {
      if (this.props.company === "C0001") {
        filtersArray = [
          ...filtersArray,
          {
            title: "Deal Cat.",
            value: this.state.deal_category__c,
          },
        ];
      }
    }
    if (this.state.disabled.filter((i) => i === "Solution_Cat").length === 0) {
      if (this.props.company === "C0015") {
        filtersArray = [
          ...filtersArray,
          {
            title: "Solution Cat.",
            value: this.state.Solution_Cat,
          },
        ];
      }
    }
    if (this.state.disabled.filter((i) => i === "Cust_Type").length === 0) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer type Type", value: this.state.Cust_Type },
      ];
    }
    if (this.state.disabled.filter((i) => i === "rep").length === 0) {
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];
    }
    if (this.state.disabled.filter((i) => i === "team").length === 0) {
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];
    }
    return filtersArray;
  };

  passFiltersForDrilldown2 = () => {
    let filtersArray = [];
    if (this.state.pcfq) {
      filtersArray = [...filtersArray, this.state.pcfq];
    }
    return filtersArray;
  };

  passFiltersForDrilldownEarliestOpenStage = () => {
    if (this.state.earliestOpenStage) {
      return this.state.earliestOpenStage;
    }
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item style={{ marginBottom: 5 }} xs={12}>
          <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Pipeline Waterfall Analysis (New)</Typography>
          <Typography variant='body1'>
            Visualize and analyze the changes to the pipeline for any combination of projected close quarter, past any stage over any time frame using the classic waterfall chart.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Pipeline Waterfall Analysis (New)",
          "Visualize and analyze the changes to the pipeline for any combination of projected close quarter, past any stage over any time frame using the classic waterfall chart."
        )}

        {this.props.waterfallFilters !== "" ? (
          <>
            <Grid
              container
              xs={12}
              alignItems="flex-start"
              style={{ marginBottom: 5 }}
            >
              <Grid
                xs={3}
                spacing={5}
                item
                container
                component={Paper}
                className={classes.repTeamFilterContainer}
              >
                <Grid item xs={6} style={{ padding: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.pcfq.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Proj. Close Fiscal Qtr.:
                    </InputLabel>
                    <Select
                      value={this.state.pcfq}
                      onChange={this.handleChangeFilter}
                      name="pcfq"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.pcfq.map((y) => (
                          <MenuItem
                            key={y["Projected_Close_Fiscal_Quarter"]}
                            value={y["Projected_Close_Fiscal_Quarter"]}
                          >
                            {y["Projected_Close_Fiscal_Quarter"]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ padding: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-stage-label"
                      style={{ color: "#4472c4", width: 144 }}
                    >
                      Earliest Open Stage:
                    </InputLabel>
                    <Select
                      labelId="select-stage-label"
                      id="select-stage"
                      value={this.state.earliestOpenStage}
                      onChange={this.handleChangeEarliestOpenStage}
                      label="Earliest Open Stage:"
                      name="earliestOpenStage"
                    >
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.stages.map(
                          (y) =>
                            y.sequence && (
                              <MenuItem key={y.sequence} value={y.sequence}>
                                {y.stage_name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                xs={4}
                item
                container
                className={classes.datesFilterContainer}
                component={Paper}
              >
                <Grid item xs={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-last-label"
                      style={{ color: "#4472c4" }}
                    >
                      Time Frame - Last:
                    </InputLabel>
                    <Select
                      labelId="select-last-label"
                      id="select-last"
                      value={this.state.last}
                      onChange={this.handleChange}
                      label="Last:"
                      name="last"
                      disabled={this.state.timeFrame === "custom"}
                    >
                      {[
                        { text: "Custom", value: 1 },
                        { text: "7 days", value: 7 },
                        { text: "15 days", value: 15 },
                        { text: "1 month", value: 30 },
                        { text: "3 months", value: 90 },
                        { text: "6 months", value: 180 },
                        { text: "1 year", value: 365 },
                      ].map((y) => (
                        <MenuItem key={y.text} value={y.value}>
                          {y.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="From start of:"
                    setDate={(date) => this.setFromDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForFrom}
                    max={this.state.maxDateForFrom}
                    current={this.state.from}
                    errorText={this.state.errorTextFrom}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 10 }}>
                  <DatePicker
                    label="To end of:"
                    setDate={(date) => this.setToDate(date)}
                    disabled={this.state.last !== 1}
                    min={this.state.minDateForTo}
                    max={this.state.maxDateForTo}
                    current={this.state.to}
                    errorText={this.state.errorTextTo}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                className={classes.repTeamFilterContainer}
                component={Paper}
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={
                      this.props.waterfallFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rep:"
                        variant="standard"
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      id="select-team-label"
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Team:
                    </InputLabel>
                    <Select
                      labelId="select-team-label"
                      id="select-team"
                      value={this.state.team}
                      onChange={this.handleChangeFilter}
                      label="Team"
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.waterfallFilters !== "" &&
                        this.props.waterfallFilters.teams
                          .map((t) => t.Display_Name)
                          .sort()
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container className={classes.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.plot}
                  disabled={
                    this.state.errorTextFrom !== "" ||
                    this.state.errorTextTo !== ""
                  }
                >
                  GO
                </Button>
              </Grid>
              {
                <Grid
                  item
                  container
                  xs={this.props.company === "C0003" ? 4 : 2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid
                    item
                    xs={this.props.company === "C0003" ? 6 : 12}
                    style={{ paddingTop: 0 }}
                  >
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Cust_Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Customer Type:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-type"
                        data-test="select-cust-type"
                        value={this.state.Cust_Type}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Cust_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.custType
                            .map((t) => t.Cust_Type)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.props.company === "C0003" && (
                    <Grid
                      item
                      xs={this.props.company === "C0003" ? 6 : 12}
                      style={{ paddingTop: 0 }}
                    >
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          id="select-team-label"
                          style={{
                            color: !this.state.Booking_Type_NL_CS_US.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                        >
                          Opportunity Type:
                        </InputLabel>
                        <Select
                          labelId="select-opp-label"
                          data-test="select-opp-type"
                          value={this.state.Booking_Type_NL_CS_US}
                          onChange={this.handleChangeFilter}
                          label=""
                          name="Booking_Type_NL_CS_US"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.waterfallFilters !== "" &&
                            this.props.waterfallFilters.oppType
                              .map((t) => t.Booking_Type_NL_CS_US)
                              .map((y) => (
                                <MenuItem key={y} value={y}>
                                  {y}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              }

              {this.props.company === "C0010" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-region-label"
                        style={{
                          color: !this.state.Record_Type_Name__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Opp Record Type:
                      </InputLabel>
                      <Select
                        data-test="Record_Type_Name__c"
                        value={this.state.Record_Type_Name__c}
                        onChange={this.handleChangeFilter}
                        name="Record_Type_Name__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {Array.isArray(
                          this.props.waterfallFilters.oppRecordTypeFilter
                        ) &&
                          this.props.waterfallFilters.oppRecordTypeFilter
                            .filter(
                              (item) =>
                                item.type === "Record_Type_Name__c" &&
                                item.Record_Type_Name__c
                            ) // Ensuring type and value are present
                            .map((item) => (
                              <MenuItem
                                key={item.Record_Type_Name__c}
                                value={item.Record_Type_Name__c}
                              >
                                {item.Record_Type_Name__c}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {this.props.company === "C0001" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.deal_category__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-deal_category__c"
                        data-test="select-cust-deal_category__c"
                        value={this.state.deal_category__c}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="deal_category__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.dealCat
                            .map((t) => t.deal_category__c)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {this.props.company === "C0015" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-Solution_Cat"
                        data-test="select-cust-Solution_Cat"
                        value={this.state.Solution_Cat}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Solution_Cat"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.solutionCat
                            .map((t) => t.Solution_Cat)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {this.props.company === "C0002" && (
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Acct_Industry.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Industry:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-Acct_Industry"
                        data-test="select-cust-Acct_Industry"
                        value={this.state.Acct_Industry}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Acct_Industry"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.waterfallFilters !== "" &&
                          this.props.waterfallFilters.industries.map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid item container spacing={2} justify="space-around">
          <Grid item xs={12}>
            {this.props.waterfallData !== "" &&
            this.props.updatingWaterfallData === false ? (
              <Card className={clsx(classes.root)} raised={true}>
                <CardHeader
                  disableTypography
                  title={
                    this.props.waterfallError ? (
                      <div style={{ color: "red" }}>
                        Insufficient data. Please choose different filters.
                      </div>
                    ) : this.props.alert.message === "Error updating data" ? (
                      "An Error occurred, please try again or choose different filters"
                    ) : (
                      <Typography variant="body1" style={{ fontWeight: 600 }}>
                        {`Change in pipeline from ${this.state.fromDisplay} to ${this.state.toDisplay}: `}
                        <span
                          style={
                            this.props.trend > 0
                              ? { color: "#70ad47" }
                              : this.props.trend < 0
                              ? { color: "#c55a11" }
                              : { color: "black" }
                          }
                        >
                          {this.props.trend > 0
                            ? "+"
                            : this.props.trend < 0
                            ? "-"
                            : ""}
                          ${d3.format(",")(Math.abs(this.props.trend))}
                        </span>
                      </Typography>
                    )
                  }
                />
                <Divider />
                <CardContent className={classes.content}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      paddingTop: window.innerWidth < 400 ? "56.25%" : "22.32%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                      }}
                      className="waterfall-container"
                      data-test="waterfall-chart"
                      // id="Waterfall_svg"
                      ref={this._setRef.bind(this)}
                    />
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Card
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                raised={true}
              >
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="60%"
                  style={{ margin: "20px 10px", alignSelf: "flex-start" }}
                />
                <Divider style={{ width: "100%" }} />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={350}
                  style={{ margin: "20px 0px" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" &&
          this.state.selectedDrilldownStageLabel !== "" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={this.state.selectedDrilldownStageLabel}
                back={this.handleChangeLocation}
                filters={[
                  {
                    title: "Projected Close Fiscal Quarter",
                    value: [].concat.apply(
                      [],
                      this.state.filtersForDrilldownpcfq
                    ),
                  },
                  {
                    title: "Earliest Open Stage",
                    value: this.props.waterfallFilters.stages
                      .map((i) => {
                        if (i.sequence !== undefined) {
                          if (
                            i.sequence ===
                            this.state.filtersForDrilldownEarliestOpenStage
                          )
                            return i.stage_name;
                        }
                      })
                      .filter((item) => item !== undefined),
                  },
                  {
                    title:
                      this.state.activelast !== 1
                        ? "Time Frame"
                        : "Custom Date Range",
                    value:
                      this.state.activelast !== 1
                        ? this.state.activelast < 30
                          ? this.state.activelast + " days"
                          : this.state.activelast === 30
                          ? "1 month"
                          : this.state.activelast === 90
                          ? "3 months"
                          : this.state.activelast === 180
                          ? "6 months"
                          : this.state.activelast === 365
                          ? "1 year"
                          : ""
                        : "",
                  },
                  { title: "From start of", value: this.state.fromDisplay },
                  { title: "To end of", value: this.state.toDisplay },

                  ...this.state.filtersForDrilldown,
                ]}
              >
                <Drilldown
                  header={this.state.selectedDrilldownStageLabel}
                  body={this.props.drilldownOnGraphData}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                  Value_Label={this.props.Value_Label}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
        {this.state.secondLevelLocation === "drilldownOppId" &&
          this.state.selectedDrilldownStageLabel !== "" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={"Opportunity Change Events"}
                back={this.handleChangeLocation}
                stepBack={this.stepBack}
                filters={[]}
              >
                <ExpectedAcvPage
                  header={this.state.selectedDrilldownStageLabel}
                  body={this.props.ExpectedAcvData}
                  Value_Label={this.props.Value_Label}
                />
              </SingleLevelDrilldown>
            </Grid>
          )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {
    waterfallFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
    waterfallError,
    alert,
    drilldownOnGraphData,
    updatingWaterfallData,
    selectedFilters,
    user,
    ExpectedAcvData,
  } = state.app;

  return {
    waterfallFilters,
    waterfallData,
    persistentAlert,
    waterfallMinDate,
    waterfallMaxDate,
    waterfallError,
    alert,
    drilldownOnGraphData,
    updatingWaterfallData,
    selectedFilters,
    ExpectedAcvData,
    company: user.company,
    Value_Label: user.Value_Label,
    trend:
      waterfallData !== ""
        ? Math.round(
            waterfallData.filter((r) => r.name === "Ending Pipeline")[0].value -
              waterfallData.filter((r) => r.name === "Starting Pipeline")[0]
                .value
          )
        : 0,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getWaterfallData: (from, to, filters) => {
    dispatch({ type: "get_waterfall_data_request" });
    dispatch({ type: "selected_filters", selectedFilters: filters });
    appService.getNewWaterfallData(from, to, filters).then(
      (json) => {
        dispatch({ type: "get_waterfall_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_waterfall_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_waterfall_data_failure", error });
      }
    );
  },
  getWaterfallMinDate: () => {
    dispatch({ type: "get_waterfall_min_date_request" });
    appService.getNewWaterfallMinDate().then(
      (json) => {
        dispatch({ type: "get_waterfall_min_date_success", json });
        dispatch({ type: "get_waterfall_data_request" });
        let stageSequence = getDefaultSelectedStage(
          json.message.filters.stages
        );
        let filters = [];
        let stage_namesOnwards = [];
        let stage_namesBefore = [];
        json.message.filters.stages.map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence >= stageSequence)
              stage_namesOnwards = [...stage_namesOnwards, i.stage_name];
          }
        });
        json.message.filters.stages.map((i) => {
          if (i.sequence !== undefined) {
            if (i.sequence < stageSequence)
              stage_namesBefore = [...stage_namesBefore, i.stage_name];
          }
        });

        filters = [
          ...filters,
          {
            name: "pcfq",
            value: getDefaultSelectedFiscalQuarters(
              json.message.filters.pcfq,
              "Projected_Close_Fiscal_Quarter"
            ),
            string: true,
          },
          {
            name: "EarliestStageOnwards",
            value: stage_namesOnwards,
            string: true,
          },
          {
            name: "StagesBeforeEarliest",
            value: stage_namesBefore,
            string: true,
          },
        ];
        dispatch({ type: "selected_filters", selectedFilters: filters });
        appService
          .getNewWaterfallData(
            json.message.filters.dateFilters.prior7Days,
            json.message.filters.dateFilters.currentDate,
            filters
          )
          .then(
            (json) => {
              dispatch({ type: "get_waterfall_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_waterfall_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_waterfall_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_waterfall_min_date_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_waterfall_min_date_failure", error });
      }
    );
  },
  getWaterfallDrilldownOnGraph: (filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.waterfallNewDrilldownOnGraph(filters).then(
      (json) => {
        json.message.columns.map((i) => {
          if (i.type === "currency") {
            json.message.opportunities.map((j) => {
              j[i.field] = parseFloat(j[i.field]);
            });
          }
        });

        let tempOpportunities = json.message.opportunities.map(
          (item, index) => {
            let x = {
              ...item,
            };
            return x;
          }
        );

        let acvChangeExists = undefined;
        json.message.columns.map((x) => {
          if (x.field === "acv_change_amount")
            acvChangeExists = "acv_change_amount";
          else if (x.field === "acvAtStage") acvChangeExists = "acvAtStage";
          else if (x.field === "acv_at_time") acvChangeExists = "acv_at_time";
          else if (x.field === "Acv_At_Time_T")
            acvChangeExists = "Acv_At_Time_T";
        });
        if (acvChangeExists === undefined) {
          tempOpportunities = json.message.opportunities.map((item, index) => {
            let x = {
              ...item,
              acv: ["", null, undefined, NaN].includes(item["acv"])
                ? 0
                : item["acv"],
            };
            return x;
          });
          tempOpportunities.sort((a, b) => (a.acv > b.acv ? -1 : 1));
        } else {
          tempOpportunities = json.message.opportunities.map((item, index) => {
            let x = {
              ...item,
              [acvChangeExists]: ["", null, undefined, NaN].includes(
                item[acvChangeExists]
              )
                ? 0
                : item[acvChangeExists],
            };
            return x;
          });
          console.log(tempOpportunities, "tempOpportunities");
          tempOpportunities.sort((a, b) =>
            a[acvChangeExists] > b[acvChangeExists] ? -1 : 1
          );
        }
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            opportunities: tempOpportunities,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
});

const connectedFunnel = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Funnel));
export { connectedFunnel as Waterfall };
