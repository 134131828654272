import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./PizzaBoxes.css"; // For CSS styles

const PizzaBoxesGraph = (props) => {
  const svgRef = useRef();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth <= 2000 ? window.innerWidth * 0.85 : window.innerWidth * 0.62,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Resize event listener to update dimensions dynamically
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth <= 2000 ? window.innerWidth * 0.85 : window.innerWidth * 0.62,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const { width, height } = dimensions;

    // Data for the pizza boxes with sizes and background colors
    const data = props.data;

    const boxHeight = 20; // Height of each pizza box
    const boxGap = 10; // Gap between boxes (vertical)
    const margin = { top: 20, right: 20, bottom: 70, left: 50 };

    // Calculate the maximum height needed for the SVG based on the data
    const maxItemsPerColumn = Math.max(...data.map(d => d.values.length));
    const svgHeight = maxItemsPerColumn * (boxHeight + boxGap) + margin.top + margin.bottom;

    // Dynamically calculate box width based on the container width and number of data points
    const availableWidth = width - margin.left - margin.right;
    const w = window.innerWidth <= 2000 ? 0.9 : 0.9
    const boxWidth = (availableWidth / data.length - boxGap) * w; // Shrink the box width to 70%

    // Create the SVG canvas with the calculated height
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", svgHeight); // Adjusted height

    // Clear previous content
    svg.selectAll("*").remove();

    // X scale for types (e.g., Big Data Analytics, Risk, etc.)
    const xScale = d3.scaleBand()
      .domain(data.map(d => d.type))
      .range([margin.left, width - margin.right])
      // .padding(0.05); // Decreased padding between horizontal groups

      // Create a tooltip div and add it to the DOM
const tooltip = d3.select("body").append("div")
.attr("class", "tooltip");

    // Stack boxes upwards
    data.forEach(d => {
      const x = xScale(d.type);
      let y = svgHeight - margin.bottom; // Adjusted to use svgHeight

      d.values.forEach(item => {
        y -= boxHeight + boxGap;

        svg.append("rect")
          .attr("x", x)
          .attr("y", y)
          .attr("width", boxWidth) // Dynamically calculated box width
          .attr("height", boxHeight)
          .attr("fill", item.bg || "white")
          .attr("stroke", "black")
          .append("title") // Add title element for tooltip
          .text(item.value);

         const t =  window.innerWidth <= 2000 ? 30 : 20

          const truncatedText = item.value.length > t ? `${item.value.slice(0, t)}...` : item.value;

        svg.append("text")
          .attr("x", x + boxWidth / 2)
          .attr("y", y + boxHeight / 2)
          .attr("fill", item.clr || "black")
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "middle")
          .style("font-size", "0.7rem")
          .text(truncatedText)
          .append("title") // Add title element for tooltip
          .text(item.value); // Display full text in the tooltip;


          // Add top-right corner shade with orange color
          if (props.notActiveProducts.includes(item.value)) {
            svg.append("polygon")
              .attr("points", `${x + boxWidth - 10},${y} ${x + boxWidth},${y} ${x + boxWidth},${y + 10}`)
              .attr("fill", "orange");
          }
      });

      

      // Add a green background for type label
      svg.append("rect")
        .attr("x", x)
        .attr("y", svgHeight - margin.bottom)
        .attr("width", boxWidth) // Dynamically calculated box width
        .attr("height", boxHeight)
        .attr("fill", "green");

      svg.append("text")
        .attr("x", x + boxWidth / 2)
        .attr("y", svgHeight - margin.bottom + boxHeight / 2)
        .attr("fill", "white")
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .style("font-size", "0.7rem")
        .text(d.type);
    });

   // Add legends at the bottom center
const legendWidth = 400; // Total width of both legends and their text
const legendX = (width - legendWidth) / 2; // Center-align legends
const legendY = svgHeight - 25; // Set a slightly larger margin above legends

// Legend 1: Blue-filled box
svg.append("rect")
  .attr("x", legendX)
  .attr("y", legendY)
  .attr("width", 20)
  .attr("height", 15) // Reduced height of the box
  .attr("fill", "#4471C4");

svg.append("text")
  .attr("x", legendX + 30)
  .attr("y", legendY + 12) // Adjusted y position to align text with smaller box
  .attr("fill", "black")
  .style("font-size", "0.8rem")
  .text("In use at this account");

// Legend 2: Blue-filled box with orange top-right corner
svg.append("rect")
  .attr("x", legendX + 200) // Increased spacing between the legends
  .attr("y", legendY)
  .attr("width", 20)
  .attr("height", 15) // Reduced height of the box
  .attr("fill", "#4471C4");

svg.append("polygon")
  .attr("points", `${legendX + 210},${legendY} ${legendX + 220},${legendY} ${legendX + 220},${legendY + 7.5}`) // Adjusted for smaller box
  .attr("fill", "orange");

svg.append("text")
  .attr("x", legendX + 230) // Adjusted for spacing after the second legend
  .attr("y", legendY + 12) // Align text with the smaller box
  .attr("fill", "black")
  .style("font-size", "0.8rem")
  .text("End of sale product in use at this account");

  }, [dimensions, props.data]);

  return (
    <div style={{ overflowY: "auto", width: "100%" }}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default PizzaBoxesGraph;
