import {
    Button,
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    withStyles,
  } from "@material-ui/core";
  import Skeleton from "@material-ui/lab/Skeleton";
  import { sort } from "d3-array";
  import React, { Component } from "react";
  import { ToggleButton, ToggleButtonGroup } from "@mui/material";
  import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
  import { connect } from "react-redux";
  import { appService } from "../../App/app.service";
  import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
  import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
  import { countOrACVFormatter, customDateFormatterNew, getDefaultSelectedFiscalQuarters } from "../../util/customFunctions";
  import addHeaderdescription from "../../components/HeaderDescription";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD"
import PizzaBoxesGraph from "./PizzaBoxesGraph";
import { Autocomplete } from "@material-ui/lab";
import DataTable from "./DataTable";

  
  const styles = (theme) => ({
    root: {},
    content: {},
    buttons: {
      display: "flex",
      justifyContent: "center",
      "& > *": {
        marginLeft: theme.spacing(1),
      },
    },
    table: {
      border: "0.5px solid lightgrey",
      padding: "2px 10px",
      color: "inherit",
    },
    tableClickable: {
      border: "0.5px solid lightgrey",
      padding: "2px 10px",
      color: "inherit",
      cursor: "pointer",
    },
    rep: {
      border: "0.5px solid lightgrey",
      padding: "2px 10px",
      width: "20%",
      color: "inherit",
    },
    tableHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "2px 10px",
      color: "inherit",
    },
    repHeader: {
      fontWeight: 600,
      border: "0.5px solid lightgrey",
      padding: "2px 10px",
      width: "20%",
      color: "inherit",
    },
    mainTable: {
      width: "100%",
      maxWidth: "100%",
      [theme.breakpoints.up(1250)]: {
        width: 1000,
      },
      [theme.breakpoints.up(1900)]: {
        width: 1400,
      },
    },
    mainTable2: {
      width: "100%",
      maxWidth: "100%",
      [theme.breakpoints.up(1250)]: {
        width: 800,
      },
      [theme.breakpoints.up(1900)]: {
        width: 1200,
      },
    },
  });
  class ProductsByAccount extends Component {
    state = {
        Account_Name:['All'],
      location: null,
      secondLevelLocation: null,
      toggleValue: "Acv",
      showWeeklyAvg: false,
      header: 'POCs Summary'
    };
    componentDidMount = () => {
      this.props.getfunnelCompareFilters();
    };
  
    componentDidUpdate(prevProps, prevState) {
      if (
        this.props.funnelCompareFilters !== "" &&
        prevProps.funnelCompareFilters === ""
      ) {
        const defaultAccountName = getDefaultSelectedFiscalQuarters(
            this.props.funnelCompareFilters.Account_Name,
            "Account_Name"
          );

         
  
        this.setState({
          ...this.state,
          Account_Name: defaultAccountName
        });
      }
    }
  
    // handleChangeToggle = (e) => {
    //   this.setState({
    //     ...this.state,
    //     toggleValue: e.target.value,
    //   });
    // };
  
    handleChange = (event, value) => {
        const selectedAccount = value ? [value] : ['All']; // Set selected value or default to 'All'
        this.setState({
          Account_Name: selectedAccount, // Update state with selected value
        });
      };
  
    setFromDate = (date) => {
      const split = this.state.to.split("-");
      const toDate = new Date(
        parseInt(split[2]),
        parseInt(split[0]) - 1,
        parseInt(split[1])
      );
      if (new Date(date) > toDate) {
        this.setState({
          ...this.state,
          from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
          errorTextFrom: "From date cannot be later than the To date",
        });
      } else {
        this.setState({
          ...this.state,
          from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
          errorTextFrom: "",
          errorTextTo: "",
        });
      }
    };
  
    setToDate = (date) => {
      const split = this.state.from.split("-");
      const fromDate = new Date(
        parseInt(split[2]),
        parseInt(split[0]) - 1,
        parseInt(split[1])
      );
      if (new Date(date) < fromDate) {
        this.setState({
          ...this.state,
          to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
          errorTextFrom: "To date cannot be earlier than the From date",
        });
      } else {
        this.setState({
          ...this.state,
          to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
          errorTextTo: "",
          errorTextFrom: "",
        });
      }
    };
  
    handleGo = () => {
      var filters = [];
  
      if(this.state.Account_Name[0] !== 'All'){
        const obj = {name: 'Account_Name', value: typeof this.state.Account_Name === 'string' ? [this.state.Acc] : this.state.Account_Name}
       filters = [...filters, obj] 
    }

  
      this.setState({ ...this.state });
      this.props.getCallsCompletedData(filters);
    };
  
    handleChangeLocationBack = (location) => {
      this.setState({
        ...this.state,
        location: location,
        secondLevelLocation: location,
      });
      //this.props.setTable('')
    };
    handleChangeLocation = (location, quarter, key) => {
      if (location !== "overview") {
        let filters = [];
        let drildownFilters = [];

        let key2 = 'active'
        if(key === 'Pocs Active at Qtr. Start'){
          key2 = `active`
        }

        if(key === 'POCs Started during Qtr.'){
          key2 = `start`
        }

        if(key === 'POCs Ended during Qtr.'){
          key2 = `end`
        }
  
       if(quarter){
        const obj = {name: 'quarter', value: typeof quarter === 'string' ? [quarter] : quarter}
        filters = [obj]
       }
  
        filters = [
          ...filters,
        ];
  
        drildownFilters = [
          ...drildownFilters,
          {
            title: "Earliest Start Quarter",
            value:
              this.state.POC_Start_Fiscal_Quarter
          },
          {
            title: "Latest Start Quarter",
            value:
              this.state.POC_End_Fiscal_Quarter
          },
          {
            title: "Fiscal Quarter",
            value:
              quarter
          }
        ];
  
  
        this.setState({
          ...this.state,
          location: location,
          secondLevelLocation: location,
          // header:key1 === 'Won' ? 'Won' : key1 === 'Open' ? `Open Pipeline (${this.state.Stage[0]} or later)` : `Lost or Disqualified (${this.state.Stage[0]} or later)`,
          filtersForDrilldown: [...drildownFilters],
          header: key
        });
  
        this.props.getCallsCompletedDrilldownData(filters, key2);
      } else {
        this.props.clearDrilldownData();
      }
    };
  
    handleSecondLevelDrilldown = (location, oppId) => {
      console.log(oppId);
      this.setState({
        ...this.state,
        // location: location,
        secondLevelLocation: location,
      });
      this.props.getOpenOppData(oppId);
    };
  
    stepBack = () => {
      this.setState({
        ...this.state,
        secondLevelLocation: "",
      });
      this.props.clearExpectedAcvData();
    };
  
    render() {
      const { classes } = this.props;
      return (
        <Grid container>
          {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
            <Typography variant='h2' align='center'>Calls Completed</Typography>
          </Grid>
          <Typography variant='body1'>
          Analyze the cadence and consistency of customer calls completed
          </Typography> */}
          {addHeaderdescription(
            "Products By Account",
            "Products By Account"
          )}
  
          {this.props.funnelCompareFilters !== "" ? (
            <Grid item container xs={12}>
              <Grid container item style={{ padding: 10 }} xs={4} md={4}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  justify="space-around"
                >
                  <Grid item xs={12} style={{ paddingTop: 10 }}>
                  {/* <FormControl
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: "#4472c4"
                      }}
                    >
                      Account Name:
                    </InputLabel>
                    <Select
                      data-test="Account_Name"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.Account_Name}
                      onChange={this.handleChange}
                      label="Account Name"
                      name="Account_Name"
                    >
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters.Account_Name
                          .filter((item) => item.Account_Name !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.Account_Name}
                              value={y.Account_Name}
                            >
                              {y.Account_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl> */}
                 <Autocomplete
        id="combo-box-demo"
        options={
          this.props.funnelCompareFilters !== "" && this.props.funnelCompareFilters.Account_Name
            .filter((item) => item.Account_Name !== "")
            .map((f) => f.Account_Name) || []
        }
        getOptionLabel={(option) => option}
        value={this.state.Account_Name[0] || null} // Ensure a valid value for Autocomplete
        onChange={this.handleChange}
        name="Account_Name"
        data-test="Account_Name"
        renderInput={(params) => (
          <TextField
            {...params}
            label="Account Name:"
            variant="standard"
            InputLabelProps={{
              style: {
                color: !this.state.Account_Name.includes("All")
                  ? "#4472c4"
                  : "#000",
              },
            }}
          />
        )}
      />
                  </Grid>
                
                
                </Grid>
              </Grid>
  
              <Grid
                item
                sm={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Card style={{ margin: "auto", width: "100%" }} raised={true}>
              <Grid item container justify="center">
                <Skeleton
                  variant="react"
                  animation="wave"
                  height={20}
                  width="90%"
                  style={{ margin: "1rem" }}
                />
              </Grid>
            </Card>
          )}
  
          <Grid item xs={12} style={{ padding: "1rem", paddingLeft: 0 }}>
            {this.props.CallsCompletedData !== "" ? (
              <Grid item container xs={12} justify="flex-start">
                <Card style={{ padding: "1rem 2rem", width: '100%' }}>
                  <Grid item container xs={12} justify="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant="h2"
                        style={{
                          width: "100%",
                          margin: "auto",
                          padding: "1rem",
                          paddingTop: "0.3rem",
                        }}
                        align="center"
                      >
                        Products By Account
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align="right">
                    </Grid>
                  </Grid>
                  <PizzaBoxesGraph data={this.props.CallsCompletedData.data} notActiveProducts={this.props.CallsCompletedData.notActiveProducts}/>
                </Card>
              </Grid>

              
            ) : (
              <Grid item container component={Card} justify="center">
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={200}
                  style={{ margin: "20px 0px" }}
                />
              </Grid>
            )}

{this.props.CallsCompletedData !== "" ? (
              <Grid item container xs={12} justify="flex-start" style={{marginTop: '1rem'}}>
                <Card style={{ padding: "1rem 2rem", width: '100%', }}>
                
                
                  <Grid item container xs={12} justify="center" >
                    
                  <DataTable data={this.props.CallsCompletedData.tableData} columns={this.props.CallsCompletedData.columns}/>
                   
                    </Grid>
                </Card>
              </Grid>

              
            ) : (
              <Grid item container component={Card} justify="center" style={{marginTop: '1rem'}}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="80%"
                  height={200}
                  style={{ margin: "20px 0px" }}
                />
              </Grid>
            )}
          </Grid>
          {/* <Grid item xs={12} style={{ padding: '1rem', paddingLeft: 0 }}>
            {
              this.props.CallsCompletedData !== "" ?
                <Grid item container xs={12} justify="flex-start">
                  <Card style={{ padding: '1rem 2rem' }}>
                  <Grid item container xs={12}justify="center">
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={10}>
                        <Typography variant='h2' style={{ width: '100%', margin: 'auto', padding: '1rem' }} align="center">Calls Completed - Not Linked to Existing Opp (grouped by team)</Typography>
                     </Grid>
                     <Grid item xs={1} align='right'>
  
                        
                        </Grid>
                     </Grid>
                    
                     <DataTable data={this.props.CallsCompletedData.dataNotLinkedtoExistingOpp} teamRep={this.props.CallsCompletedData.repTeamObj} timeFrames={this.props.CallsCompletedData.uniqueTimeFrames}/>
  
                  </Card>
                </Grid>
                :
                <Grid item container component={Card} justify="center"><Skeleton animation="wave" variant="rect" width='80%' height={200} style={{ margin: '20px 0px' }} /></Grid>
            }
          </Grid> */}
  
          {this.state.location === "drilldownOnGraph" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={this.state.header}
                back={this.handleChangeLocationBack}
                filters={this.state.filtersForDrilldown}
              >
                <Drilldown
                  header={this.state.header}
                  body={this.props.CallsCompletedDrilldownData}
                  handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                />
                {/* <Drilldown body={this.props.CallsCompletedDrilldownData} isMetaData={this.state.isMetaData} metaData={this.state.metaData} drilldownLabelString={this.state.drilldownLabelString} 
                 company={this.props.company} 
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                 />   */}
                                 

              </SingleLevelDrilldown>
            </Grid>
          )}
          {this.state.secondLevelLocation === "drilldownOppId" && (
            <Grid
              item
              xs={12}
              style={{ position: "absolute", width: "100%", marginTop: "30px" }}
            >
              <SingleLevelDrilldown
                header={"Opportunity Change Events"}
                back={this.handleChangeLocationBack}
                stepBack={this.stepBack}
                filters={[]}
              >
                <ExpectedAcvPage body={this.props.ExpectedAcvData} />
              </SingleLevelDrilldown>
            </Grid>
          )}
        </Grid>
      );
    }
  }
  const mapStateToProps = (state) => {
    var {
      user,
      ProductsByAccountData,
      drilldownOnGraphData,
      ExpectedAcvData,
      ProductsByAccountFilters,
      currentFilters,
    } = state.app;
  
    return {
      company: user.company,
      CallsCompletedData:ProductsByAccountData , 
      CallsCompletedDrilldownData: drilldownOnGraphData,
      funnelCompareFilters: ProductsByAccountFilters,
      ExpectedAcvData,
      currentFilters,
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    clearDrilldownData: () => {
      dispatch({ type: "clear_ProductsByAccount_drilldown_data" });
    },
    getfunnelCompareFilters: (company) => {
      dispatch({ type: "get_ProductsByAccount_filters_request" });
      appService.getProductsByAccountFilters().then(
        (json) => {
          dispatch({ type: "get_ProductsByAccount_filters_success", json });
          dispatch({ type: "get_ProductsByAccount_data_request" });
          const defaultAccountName = getDefaultSelectedFiscalQuarters(
            json.message.Account_Name,
            "Account_Name"
          );
         
          let filters = [];
        
          if (defaultAccountName.length > 0) {
            filters = [...filters, { name: "Account_Name", value: defaultAccountName }];
          }
          dispatch({ type: "current_selected_filters", filters });
          appService.getProductsByAccountData(filters).then(
            (json) => {
              dispatch({ type: "get_ProductsByAccount_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_ProductsByAccount_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_ProductsByAccount_data_failure", error });
            }
          );
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_ProductsByAccount_filters_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_ProductsByAccount_filters_failure", error });
        }
      );
    },
    getCallsCompletedDrilldownData: (filters, key) => {
      dispatch({ type: "get_drilldown_on_graph_request" });
      appService.getProductsByAccountdDrilldownData(filters, key).then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
            a.acvAtStage > b.acvAtStage ? -1 : 1
          );
          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });
  
          json = {
            ...json,
            message: {
              ...json.message,
              columns: json.message.columns,
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
            },
          };
  
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
    },
    getCallsCompletedData: (filters) => {
      dispatch({ type: "get_ProductsByAccount_data_request" });
      dispatch({ type: "current_selected_filters", filters });
      appService.getProductsByAccountData(filters).then(
        (json) => {
          dispatch({ type: "get_ProductsByAccount_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_ProductsByAccount_data_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_ProductsByAccount_data_failure", error });
        }
      );
    },
    getOpenOppData: (oppId) => {
      dispatch({ type: "get_expectedAcv_drilldown_data_request" });
      appService.getWaterfallOppData(oppId).then(
        (json) => {
          dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_expectedAcv_drilldown_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
        }
      );
    },
    clearExpectedAcvData: () => {
      dispatch({ type: "clear_expected_acv_data" });
    },
  });
  const connectedProductsByAccount = connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ProductsByAccount));
  export default connectedProductsByAccount;
  